import React, { useState, useEffect } from 'react';
import Button from '../Button';
import FormInputField from '../FormInputField/FormInputField';
import { useForm, ValidationError } from '@formspree/react';
import { navigate } from 'gatsby';
import * as styles from './Contact.module.css';

const Contact = (props) => {

  const [state, handleSubmit] = useForm("xqkrabep");

  useEffect(() => {
    if (state.succeeded) {
      navigate('/');
    }
  }, [state.succeeded]);



  const initialState = {
    name: '',
    email: '',
    comment: '',
  };

  const [contactForm, setContactForm] = useState(initialState);

  const handleChange = (id, e) => {
    const tempForm = { ...contactForm, [id]: e };
    setContactForm(tempForm);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   setContactForm(initialState);
  // };

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h4>Send Us A Message</h4>

      </div>




      <div className={styles.contactContainer}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.contactForm}>
            <FormInputField
              id={'name'}
              value={contactForm.name}
              handleChange={(id, e) => handleChange(id, e)}
              type={'text'}
              labelName={'Full Name'}
              required
            />
            <FormInputField
              id={'email'}
              value={contactForm.email}
              handleChange={(id, e) => handleChange(id, e)}
              type={'email'}
              labelName={'Email'}
              required
            />
            <div className={styles.commentInput}>
              <FormInputField
                id={'comment'}
                value={contactForm.comment}
                handleChange={(id, e) => handleChange(id, e)}
                type={'textarea'}
                labelName={'Comments / Questions'}
                required
              />
              <ValidationError prefix="Email" field="email" errors={state.errors} />
            </div>
          </div>
          <Button
            className={styles.customButton}
            level={'primary'}
            type={'buttonSubmit'}
            disabled={state.submitting}
          >

            submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
